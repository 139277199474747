import React from 'react';
import i18n from "i18next";

// Define a type for the component props
interface UnixTimestampConverterProps {
    timestamp: number; // Expecting a Unix timestamp as a number
}

const UnixTimestampConverter: React.FC<UnixTimestampConverterProps> = ({ timestamp }) => {
    // Create a Date object from the Unix timestamp (Note: Unix timestamp is in seconds, JavaScript Date object expects milliseconds)
    const date = new Date(timestamp * 1000);

//Create an Intl.DateTimeFormat instance with desired options
    const formatter = new Intl.DateTimeFormat(i18n.language, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        //second: '0-digit',
        //timeZoneName: 'none',
        hour12: false // Use 24-hour format
    });

// Format the date
    //const formattedDate = formatter.format(date);
    // Format the date as a string in the local timezone
    const dateString = formatter.format(date);   //date.toLocaleString();

    return (
        <div>
            {dateString}
        </div>
    );
};

export default UnixTimestampConverter;