// LanguageOption.ts

export interface LanguageOption {
    code: string;
    name: string;
}

const languages: LanguageOption[] = [
    { code: 'ar', name: 'العربية' }, // Arabic
    { code: 'de', name: 'Deutsch' }, // German
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' }, // Spanish
    { code: 'fa', name: 'فارسی' }, // Persian
    { code: 'fr', name: 'Français' },
    { code: 'he', name: 'עברית' }, // Hebrew, already included
    { code: 'hi', name: 'हिन्दी' }, // Hindi
    { code: 'it', name: 'Italiano' },
    { code: 'ja', name: '日本語' }, // Japanese
    { code: 'nl', name: 'Nederlands' }, // Dutch
    { code: 'pt', name: 'Português' }, // Portuguese
    { code: 'ru', name: 'Русский' }, // Russian
    { code: 'zh', name: '中文' } // Chinese
    // Add more languages as needed
];

export default languages;