// LanguageOption.ts

export interface CurrencyOption {
    code: string;
    name: string;
    symbol: string;
}

export const currencies: CurrencyOption[] = [
    {'code': 'USD', 'name': 'United States Dollar', 'symbol': '$ '},
    {'code': 'EUR', 'name': 'Euro', 'symbol': '€ '},
    {'code': 'JPY', 'name': 'Japanese Yen', 'symbol': '¥ '},
    {'code': 'GBP', 'name': 'Great Britain Pound', 'symbol': '£ '},
    {'code': 'AUD', 'name': 'Australian Dollar', 'symbol': 'A $ '},
    {'code': 'CAD', 'name': 'Canadian Dollar', 'symbol': 'C $ '},
    {'code': 'CHF', 'name': 'Swiss Franc', 'symbol': 'CHF '},
    {'code': 'CNY', 'name': 'Chinese Yuan', 'symbol': '¥ '},
    {'code': 'SEK', 'name': 'Swedish Krona', 'symbol': 'S kr '},
    {'code': 'NZD', 'name': 'New Zealand Dollar', 'symbol': 'NZ $ '},
    {'code': 'MXN', 'name': 'Mexican Peso', 'symbol': 'M $ '},
    {'code': 'SGD', 'name': 'Singapore Dollar', 'symbol': 'S $ '},
    {'code': 'HKD', 'name': 'Hong Kong Dollar', 'symbol': 'HK $ '},
    {'code': 'NOK', 'name': 'Norwegian Krone', 'symbol': 'N kr '},
    {'code': 'KRW', 'name': 'South Korean Won', 'symbol': '₩ '},
    {'code': 'TRY', 'name': 'Turkish Lira', 'symbol': '₺ '},
    {'code': 'RUB', 'name': 'Russian Ruble', 'symbol': '₽ '},
    {'code': 'INR', 'name': 'Indian Rupee', 'symbol': '₹ '},
    {'code': 'BRL', 'name': 'Brazilian Real', 'symbol': 'R $ '},
    {'code': 'ZAR', 'name': 'South African Rand', 'symbol': 'R '},
    {'code': 'DKK', 'name': 'Danish Krone', 'symbol': 'D kr '},
    {'code': 'PLN', 'name': 'Polish Zloty', 'symbol': 'zł '},
    {'code': 'THB', 'name': 'Thai Baht', 'symbol': '฿ '},
    {'code': 'IDR', 'name': 'Indonesian Rupiah', 'symbol': 'Rp '},
    {'code': 'HUF', 'name': 'Hungarian Forint', 'symbol': 'Ft '},
    {'code': 'CZK', 'name': 'Czech Koruna', 'symbol': 'Kč '},
    {'code': 'ILS', 'name': 'Israeli New Shekel', 'symbol': '₪ '},
    {'code': 'CLP', 'name': 'Chilean Peso', 'symbol': 'C $ '},
    {'code': 'PHP', 'name': 'Philippine Peso', 'symbol': '₱ '},
    {'code': 'AED', 'name': 'United Arab Emirates Dirham', 'symbol': 'د.إ '},
    {'code': 'COP', 'name': 'Colombian Peso', 'symbol': 'C $ '},
    {'code': 'SAR', 'name': 'Saudi Riyal', 'symbol': 'ر.س '},
    {'code': 'IRR', 'name': 'Iranian Rial', 'symbol': '﷼ '}
];

export default currencies;