// App.tsx
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MithqalDataFetcher from './components/MithqalDataFetcher';
import DisplayData from './components/DisplayData';
//import logo from "./logo.svg";
import './App.css';
import './utilities/i18n';
import i18n from "i18next";
import LanguageSelector from "./utilities/LanguageSelector";
import CurrencySelector from "./utilities/CurrencySelector";
import Container from "./utilities/Container";
import {CurrencyOption} from "./utilities/CurrencyOptions";

type CurrencyCode = string;

interface CurrencyRates extends Record<CurrencyCode, number> {}

interface Forex {
    rates: CurrencyRates;
    ts: number
}
interface PriceData {
    prices: {
        silver: number;
        gold: number;
    };
    ts: number;
}

interface MithqalData {
    "forexData":Forex;
    "metalsData":PriceData
}

// Default for the selectedCurrency
const initialCurrency: CurrencyOption = {
    code: 'USD',
    name: 'United States Dollar',
    symbol: '$'
};

const App: React.FC = () => {
    const {t} = useTranslation();
    // this effect runs once on the component mounting because the dependecies are empty []
    useEffect(() => {
        const browserLang = navigator.language;
        i18n.changeLanguage(browserLang);
    }, []);

    // Brings back value of current symbol $, C$, etc from the selectCurrency Component via the
    // handleSelectedCurrency callback that is passed to the selectCurrency component.
    // This then gets passed down to the Display components as a prop  (property)
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyOption>(initialCurrency);

    const handleSelectedCurrency = (selectedCurrency: CurrencyOption) => {
        setSelectedCurrency(selectedCurrency); // Update state with the selected currency symbol
    };

    // Brings back value of all the data fetched by the MithqalDataFetcher Component via the
    // handleDataReceived callback that is passed to the MithqalDataFetcher component.
    // This data then gets passed down to the Display components as a prop  (property)
    const [data, setData] = useState<MithqalData | null>(null);

    const handleDataReceived = (data: MithqalData) => {
        setData(data);
    };

    return (
        <div className="App">
            <header className="App-header">
                {/*<img src={logo} className="App-logo" alt="logo"/>*/}
                <div>
                    <Container style={{ backgroundColor: "grey" }}><p>{t("Language")}</p><LanguageSelector/></Container>
                    <Container style={{ backgroundColor:"darkgray" }}><p>{t("Currency")}</p><CurrencySelector onSelect={handleSelectedCurrency} /></Container>
                    <MithqalDataFetcher onChangeData={handleDataReceived}/>
                    <Container style={{ backgroundColor:"gray" }}><DisplayData currencySelected={selectedCurrency} data={data}/></Container>
                </div>
            </header>
        </div>
    )
        ;
};

export default App;

