import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import UnixTimestampConverter from '../utilities/UnixTimestampConverter'; // Adjust the import path as necessary
import Container from "../utilities/Container";
import {CurrencyOption} from "../utilities/CurrencyOptions";
import i18n from "i18next";

type CurrencyCode = string;

interface CurrencyRates extends Record<CurrencyCode, number> {}

interface Forex {
    rates: CurrencyRates;
    ts: number
}
interface PriceData {
    prices: {
        silver: number;
        gold: number;
    };
    ts: number;
}

interface MithqalData {
    forexData:Forex;
    metalsData:PriceData
}

// Props interface to type-check the props received by the component
interface DisplayDataProps {
    data: MithqalData | null,
    currencySelected: CurrencyOption;
};

const DisplayData: React.FC<DisplayDataProps> = ({ currencySelected,data }) => {
    const {t} = useTranslation();
    const currencyGoldRef = useRef<number>(0);
    const currencySilverRef = useRef<number>(0);
    const mithqalsGoldRef = useRef<number>(0);
    const mithqalsSilverRef = useRef<number>(0);

    ;
    const silverMithqalsRef = useRef<number>(0);
    const goldMithqalsRef = useRef<number>(0);
    const [inputMithqalGoldValue, setInputMithqalGoldValue] = useState<string>(''); // State to hold the input value
    const [inputCurrencyGoldValue, setInputCurrencyGoldValue] = useState<string>(''); // State to hold the input value
    const [inputMithqalSilverValue, setInputMithqalSilverValue] = useState<string>(''); // State to hold the input value
    const [inputCurrencySilverValue, setInputCurrencySilverValue] = useState<string>(''); // State to hold the input value
    const currencyX = useRef<number>(1);

    // Functions to handle input changes in the UI
    const handleInputCurrencyGoldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputCurrencyGoldValue(e.target.value);
        currencyGoldRef.current = Number(Number(e.target.value).toFixed(2));
        // @ts-ignore
        goldMithqalsRef.current = (((data.metalsData.prices.gold / 31.1035) * 3.642)*currencyX.current).toFixed(2)
    };

    const handleInputMithqalGoldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputMithqalGoldValue(e.target.value);
        mithqalsGoldRef.current = Number(Number(e.target.value).toFixed(2));
    };

    const handleInputCurrencySilverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputCurrencySilverValue(e.target.value);
        currencySilverRef.current = Number(Number(e.target.value).toFixed(2));
        // @ts-ignore
        silverMithqalsRef.current = (((data.metalsData.prices.silver / 31.1035) * 3.642)*currencyX.current).toFixed(2)
    };

    const handleInputMithqalSilverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputMithqalSilverValue(e.target.value);
        mithqalsSilverRef.current = Number(Number(e.target.value).toFixed(2));
    };

    if (!data) {
        return <p>Loading data...</p>;
    }

    // @ts-ignore
    goldMithqalsRef.current = (((data.metalsData.prices.gold / 31.1035) * 3.642)*currencyX.current).toFixed(2)
    // @ts-ignore.
    silverMithqalsRef.current = (((data.metalsData.prices.silver / 31.1035) * 3.642)*currencyX.current).toFixed(2)
    currencyX.current = Number(Number(data.forexData.rates[currencySelected.code]).toFixed(6));

    return (
        <div>
            <Container style={{backgroundColor: "GrayText"}}>
                <h4>{t("Market Exchange Rates as of")}</h4>
                <h4><UnixTimestampConverter timestamp={data.forexData.ts} ></UnixTimestampConverter></h4>
                <p></p>
                <Container style={{backgroundColor: "darkgray"}}>
                    < h4> {t("United States Dollar")}
                        <br></br>
                        $ 1
                    </h4>
                    <p></p>
                    <h4>{t(currencySelected.name)} ({currencySelected.code})
                        <br></br>
                        {currencySelected.symbol} {new Intl.NumberFormat(i18n.language).format(currencyX.current)}
                    </h4>
                </Container>
                <p></p>
                <h4>{t("Market Prices as of")}</h4>
                <h4><UnixTimestampConverter timestamp={data.metalsData.ts} ></UnixTimestampConverter></h4>
                <p></p>
                <Container style={{backgroundColor: "darkgray"}}>
                    < h4> {t("1 Mithqal")}</h4>
                                {t("is")}
                    <h4>{t(new Intl.NumberFormat(i18n.language).format(3.642))} {t("grams")}</h4>
                </Container>
                <p></p>
                <Container style={{backgroundColor: "darkgray"}}>
                    < h4> {t("1 oz(t) of Gold")}
                        <br></br>
                        {currencySelected.symbol} {new Intl.NumberFormat(i18n.language).format(((data.metalsData.prices.gold) * currencyX.current))}
                    </h4>
                    <p></p>
                    <h4>{t("1 oz(t) of Silver")}
                        <br></br>
                        {currencySelected.symbol} {new Intl.NumberFormat(i18n.language).format(((data.metalsData.prices.silver) * currencyX.current))}
                    </h4>
                </Container>
                <p></p>
                <Container style={{backgroundColor: "darkgray"}}>
                    <h4>{t("1 Mithqal of Gold")}
                        <br></br>
                        {currencySelected.symbol} {new Intl.NumberFormat(i18n.language).format((((data.metalsData.prices.gold / 31.1035) * 3.642) * currencyX.current))}
                    </h4>
                    <p></p>
                    <h4>{t("1 Mithqal of Silver")}
                        <br></br>
                        {currencySelected.symbol} {new Intl.NumberFormat(i18n.language).format((((data.metalsData.prices.silver / 31.1035) * 3.642) * currencyX.current))}
                    </h4>
                </Container>
            </Container>
            <p></p>
            <h4>{t("Converting Currency and Gold Mithqals")}</h4>
            <Container style={{backgroundColor: "darkgray"}}>
                <h4>{t("Currency")}</h4>
                {currencySelected.symbol}<input
                type="number"
                value={inputCurrencyGoldValue}
                onChange={handleInputCurrencyGoldChange}
                placeholder="0:00"
                style={{opacity: inputCurrencyGoldValue ? 1 : 0.5}} // Optional: Adjust opacity based on input
            />
                <h4> {new Intl.NumberFormat(i18n.language).format((currencyGoldRef.current / goldMithqalsRef.current))} {t("Gold Mithqals")}</h4>
            </Container>
            <p></p>
            <Container style={{backgroundColor: "darkgray"}}>
                <h4>{t("Gold Mithqals")}</h4>
                <input
                    type="number"
                    value={inputMithqalGoldValue}
                    onChange={handleInputMithqalGoldChange}
                    placeholder="0.00"
                    style={{opacity: inputMithqalGoldValue ? 1 : 0.5}} // Optional: Adjust opacity based on input
                />
                <h4> {currencySelected.symbol}{new Intl.NumberFormat(i18n.language).format((mithqalsGoldRef.current * goldMithqalsRef.current))}</h4>
            </Container>
            <p></p>
            <h4>{t("Converting Currency and Silver Mithqals")}</h4>
            <Container style={{backgroundColor: "darkgray"}}>
                <h4>{t("Currency")}</h4>
                {currencySelected.symbol}<input
                type="number"
                value={inputCurrencySilverValue}
                onChange={handleInputCurrencySilverChange}
                placeholder="0:00"
                style={{opacity: inputCurrencySilverValue ? 1 : 0.5}} // Optional: Adjust opacity based on input
            />
                <h4> {new Intl.NumberFormat(i18n.language).format((currencySilverRef.current / silverMithqalsRef.current))} {t("Silver Mithqals")}</h4>
            </Container>
            <p></p>
            <Container style={{backgroundColor: "darkgray"}}>
                <h4>{t("Silver Mithqals")}</h4>
                <input
                    type="number"
                    value={inputMithqalSilverValue}
                    onChange={handleInputMithqalSilverChange}
                    placeholder="0.00"
                    style={{opacity: inputMithqalSilverValue ? 1 : 0.5}} // Optional: Adjust opacity based on input
                />
                <h4>{currencySelected.symbol}{(new Intl.NumberFormat(i18n.language).format(((mithqalsSilverRef.current * silverMithqalsRef.current))))}</h4>
            </Container>
        </div>
    )
}

export default DisplayData;