import React, { ReactNode } from 'react';

interface ContainerProps {
    children: ReactNode;
    style?: React.CSSProperties;
}

const Container: React.FC<ContainerProps> = ({ children, style }) => {
    return (
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px', ...style }}>
            {children}
        </div>
    );
};

export default Container;