import React from 'react';
import {currencies, CurrencyOption} from "./CurrencyOptions";
import {useTranslation} from "react-i18next"; // Adjust the path as necessary

interface CurrencySelectorProps {
    onSelect: (currencySelected: CurrencyOption) => void;
}

const CurrencySelector: React.FC<CurrencySelectorProps> = ({ onSelect }) => {
    const {t} = useTranslation();

    return (
        <select onChange={(e) => {
            const selectedCurrency = currencies.find(currency => currency.code === e.target.value);
            if (selectedCurrency) onSelect(selectedCurrency);
        }}>
            {currencies.map(currency => (
                <option key={currency.code} value={currency.code}>
                    {t(currency.name)}
                </option>
            ))}
        </select>
    );
};

export default CurrencySelector;
