import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import translationAR from '../translations/ar.json';
import translationDE from '../translations/de.json';
import translationEN from '../translations/en.json';
import translationES from '../translations/es.json';
import translationFA from '../translations/fa.json';
import translationFR from '../translations/fr.json';
import translationHE from '../translations/he.json';
import translationHI from '../translations/hi.json';
import translationIT from '../translations/it.json';
import translationJA from '../translations/ja.json';
import translationNL from '../translations/nl.json';
import translationPT from '../translations/pt.json';
import translationRU from '../translations/ru.json';
import translationZH from '../translations/zh.json';


// Translation resources
const resources = {
    en: { translation: translationEN,},
    ar: { translation: translationAR,},
    de: { translation: translationDE,},
    es: { translation: translationES,},
    fa: { translation: translationFA,},
    fr: { translation: translationFR,},
    he: { translation: translationHE,},
    hi: { translation: translationHI,},
    it: { translation: translationIT,},
    ja: { translation: translationJA,},
    nl: { translation: translationNL,},
    pt: { translation: translationPT,},
    ru: { translation: translationRU,},
    zh: { translation: translationZH,},
};

// i18next instance initialization
i18n
    .use(initReactI18next) // Passes the i18n instance to react-i18next.
    .init({
        resources,
        lng: "en", // Default language
        fallbackLng: "en", // Fallback language
        interpolation: {
            escapeValue: false, // React already escapes values
        },
    });
// Function to format numbers using the current i18next language

export default i18n;