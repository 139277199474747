import React, {useRef} from 'react';
import languages from './LanguageOptions';
import {useTranslation} from "react-i18next";

const LanguageSelector: React.FC = () => {
    const { i18n } = useTranslation();
    const languageCodeRef = useRef<string>('');

    const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        languageCodeRef.current = event.target.value;
        i18n.changeLanguage(event.target.value);
    };

    return (
        <select onChange={changeLanguage} defaultValue={navigator.language.substring(0,2)} >
            {languages.map((lang) => (
                <option key={lang.code} value={lang.code}>
                    {lang.name}
                </option>
            ))}
        </select>
    );
};

export default LanguageSelector;
