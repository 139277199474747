import React, { useEffect } from 'react';

type CurrencyCode = string;

interface CurrencyRates extends Record<CurrencyCode, number> {}

interface Forex {
    rates: CurrencyRates;
    ts: number
}
interface PriceData {
    prices: {
        silver: number;
        gold: number;
    };
    ts: number;
}

interface MithqalData {
    "forexData":Forex;
    "metalsData":PriceData
}

interface MithqalDataFetcherProps {
    onChangeData: (data: MithqalData) => void;
}
const MithqalDataFetcher: React.FC<MithqalDataFetcherProps> = ({ onChangeData }) => {
    useEffect(() => {
        const fetchMithqalData = () => {

            fetch(process.env.PUBLIC_URL + '/MithqalData.json')
                .then(response => {
                    if (!response.ok) {
                        throw new Error("The network response was not ok");
                    }
                    return response.json();
                })
                .then((jsonData: MithqalData) => {
                    if (jsonData) {
                        onChangeData(jsonData); // Pass the data to the parent component
                    } else {
                        throw new Error("Bad Json data");
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };

        fetchMithqalData(); // Fetch data immediately on mount

        // Set up the interval to fetch data every 10 minutes
        const intervalId = setInterval(fetchMithqalData, 10 * 60 * 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Depend on onDataFetched to re-setup the interval if it changes

    return null; // This component doesn't render anything itself
};

export default MithqalDataFetcher;











